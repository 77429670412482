import About from "./About.js";
import Team from "./Team.js";
import Vision from "./Vision";
const Main = ({ teamCollection }) => {
  return (
    <main>
      <About />
      <Vision />
      <Team teamCollection={teamCollection} />
    </main>
  );
};

export default Main;
