import useContentful from "../useContentful";
import { FadeIn } from "./common/scrollrevel/ScrollAnimation";

const query = `query {
articleCollection(limit: 20){
  items{
    title
    description
    date
    link
  }
}
}`;

const Articles = () => {
  const { data, error } = useContentful(query);

  const date = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString();
  };

  if (!data) {
    return <div>loading articles</div>;
  } else if (error) {
    return (
      <div>An error occured loading articles, please refresh the page</div>
    );
  } else
    return (
      <section className="bg-gray-50 py-20" id="articles">
        <div className="mx-auto w-5/6">
          <h2 className="text-left mt-20 mb-3 ">ARTICLES</h2>
          <p className="text-left mb-12 text-3xl">
            Every now and then <br />
            we share our thoughts.
          </p>

          {data.articleCollection.items.map((article, idx) => {
            return (
              <FadeIn key={idx}>
                <article className="flex-col flex my-8 bg-white p-6">
                  <a
                    className="text-2xl text-gray-500 font-bold"
                    href={article.link}
                    target="_blanck"
                  >
                    {article.title}
                  </a>
                  <time className="text-gray-500">{date(article.date)}</time>
                  <p className="my-4">{article.description}</p>
                  <a
                    href={article.link}
                    alt={article.title}
                    target="_blanck"
                    className="text-yellow-600"
                  >
                    Read article
                  </a>
                </article>
              </FadeIn>
            );
          })}
        </div>
      </section>
    );
};

export default Articles;
