import Header from "./Header";
import hero from "./images/hero-image.jpg";
import arrow from "./svgs/right-arrow.svg";
import useSticky from "../useSticky";
// import camera from "./security-camera.svg";
import {
  FadeIn,
  SlideLeft,
  // RevealScroll,
} from "./common/scrollrevel/ScrollAnimation";

const Hero = () => {
  const { isSticky, element } = useSticky();

  return (
    <>
      <div
        className="hero-background mb-12 top-0 h-4/5"
        style={{
          background: `url(${hero}) rgb(255,255,255, 0.36)`,
          backgroundPosition: "center center",
        }}
      >
        <Header sticky={isSticky} />
        <div
          className="my-12 pt-12 pb-40 w-5/6 mx-auto flex flex-col"
          ref={element}
        >
          <FadeIn>
            <h1 className="sm:mt-8 sm:text-5xl font-extrabold md:leading-tight text-4xl  text-left text-shadow">
              Security Strategy, <br />
              Advisory and Consultancy.
            </h1>
            <p className="text-lg">
              First class security solutions, tips and tricks from a position of
              expertise.
              <br /> Our solutions covers organizations - big and small and{" "}
              <br />
              Government establishments and private businesses.
            </p>
          </FadeIn>

          <a
            href="mailto:steveokwori@sticmirac.com.ng"
            className="p-4 w-48 appointment-button flex items-center mb-12 mt-10 justify-between text-white"
          >
            <span className="mr-2">Get in touch</span>
            <SlideLeft>
              <img src={arrow} alt="arrow icon" width="35.12px" />
            </SlideLeft>
          </a>
        </div>
      </div>

      <h2
        className="text-center mx-4 mb-4 sm:mb-0 mt-40 font-semibold text-3xl"
        id="services"
      >
        Welcome to Sticmirac International Limited
      </h2>
      <p className="text-center mx-4">
        We provide competent security consulting services that works; long term
        and short term. <br />
        Our solutions understands the need for top-notch National and global
        security implementations.
      </p>
      <div className="flex w-11/12 md:flex-nowrap flex-wrap mx-auto mt-8 mb-40 transform justify-between">
        <div className="sm:w-1/3 w-full p-3 bg-gray-50 m-4">
          <div className="rounded-full mb-3 mx-auto shadow-lg w-16 h-16 p-3 flex items-center">
            <svg
              version="1.1"
              fill="#E17055"
              width="34"
              height="34"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 438.3 438.3"
              style={{ enableBackground: "new 0 0 438.3 438.3" }}
              xmlSpace="preserve"
              className="mx-auto max-w-full"
            >
              <g>
                <g>
                  <path
                    class="st0"
                    d="M436.7,20.5c-1.1-0.7-2.5-0.8-3.6-0.2c-17.4,9.2-35.8,13.9-54.6,13.9c-31.9,0-54.3-13.6-54.5-13.8
			c-1.2-0.7-2.6-0.7-3.8,0c-0.2,0.1-22.6,13.8-54.5,13.8c0,0,0,0,0,0c-6.1,0-12.2-0.5-18.2-1.5c0.7,0.6,1.3,1.2,1.9,1.9
			c17,17.1,25.4,42.1,25.1,74.4c-0.3,30.5-6,57.5-16.4,79.6c17.3,27.2,38.1,48.8,62,64c0.6,0.4,1.3,0.6,1.9,0.6s1.3-0.2,1.9-0.6
			c23.9-15.3,44.8-36.9,62.1-64.2c13.8-21.7,25.3-47.1,34.3-75.5c15.2-48.2,17.8-88.8,17.9-89.2C438.4,22.4,437.8,21.2,436.7,20.5z
			 M400,106.4c-8.4,26.6-19.2,50.3-31.9,70.5c-8.4,13.3-17.9,25.2-28.1,35.4V50.1c12.6,3.7,25.5,5.5,38.5,5.5
			c11.7,0,23.4-1.5,34.8-4.6C410.9,65.3,406.8,85,400,106.4z"
                  />
                  <path
                    class="st0"
                    d="M275.5,276.6L226.6,254l-23.5-19.8l-37.5,37.3l16.7,111.9c0.1,0.4,0,0.8-0.3,1.1l-17.7,24.3
			c-0.3,0.4-0.7,0.6-1.2,0.6s-0.9-0.2-1.2-0.6l-17.7-24.3c-0.2-0.3-0.3-0.7-0.3-1.1l16.7-111.9l-37.5-37.3L99.8,254l-48.9,22.5
			c-7.2,3-15,8.2-18,15.3c0,0-53.2,126.5-24.3,126.5h309.2c28.8,0-24.3-126.5-24.3-126.5C290.3,284.3,282.7,279.5,275.5,276.6z"
                  />
                  <path
                    class="st0"
                    d="M163.2,233.5c50,0,87.3-46.6,88.2-124.7c0.5-54.2-25.3-86.7-88.2-86.7S74.5,54.6,75,108.8
			C75.9,186.9,113.2,233.5,163.2,233.5z"
                  />
                </g>
              </g>
            </svg>
          </div>

          <h3 className="text-center font-extrabold font-orange">
            SECURITY <br /> CONSULTANCY
          </h3>
          <p className="mb-3 px-0 text-center">
            Sticmirac International offers first class security strategies, tips
            and tricks from a position of expertise. Our Security solutions
            covers organizations - big and small, Government establishments and
            private businesses.
          </p>
        </div>

        <div className="sm:w-1/3 w-full p-3 bg-gray-50 m-4">
          <div className="rounded-full mb-3 mx-auto shadow-lg w-16 h-16 p-3 flex items-center">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 535.5 535.5"
              fill="#E17055"
              width="30"
              height="30"
              style={{ enableBackground: "new 0 0 535.5 535.5" }}
              xmlSpace="preserve"
              className="mx-auto max-w-full"
            >
              <g>
                <g id="notifications-on">
                  <path
                    className="st0"
                    stroke="#e17155c7"
                    d="M142.8,53.5l-35.7-35.7C45.9,63.8,5.1,135.1,0,216.8h51C56.1,147.9,89.2,89.2,142.8,53.5z M484.5,216.8h51
			c-5.1-81.6-43.4-153-104.5-198.9l-35.7,35.7C446.2,89.2,479.4,147.9,484.5,216.8z M433.5,229.5c0-79.1-53.5-142.8-127.5-160.6V51
			c0-20.4-17.9-38.2-38.2-38.2S229.5,30.6,229.5,51v17.8C155.6,86.7,102,150.4,102,229.5v140.2l-51,51v25.5h433.5v-25.5l-51-51
			V229.5z M267.8,522.8c2.5,0,7.6,0,10.2,0c17.9-2.5,30.6-15.3,35.7-30.6c2.5-5.1,5.1-12.8,5.1-20.4h-102
			C216.8,499.8,239.7,522.8,267.8,522.8z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h3 className="text-center font-extrabold font-orange">
            SECURITY <br /> AWARENESS
          </h3>
          <p className="mb-3 px-0 text-center">
            Being aware of your surroundings is the first step to a better
            society. Sticmirac International offers dexterous security awareness
            programs for the betterment of organizations.
          </p>
        </div>

        <div className="sm:w-1/3 w-full p-3 bg-gray-50 m-4">
          <div className="rounded-full mb-3 mx-auto shadow-lg w-16 h-16 p-3 flex items-center content-center">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              fill="#E17055"
              viewBox="0 0 242.2 242.2"
              width="30"
              height="30"
              style={{ enableBackground: "new 0 0 242.2 242.2" }}
              xmlSpace="preserve"
              className="mx-auto max-w-full"
            >
              <path
                class="st0"
                d="M190.4,134.1L163,176.2l-42.6-27.7l-15.6,26.6L38,180.9v46.4H0v-120h38v43.5l48.7-4.2l8.6-14.5l-58.4-38
	l51.5-79.2L242.2,115L190.4,134.1z M173.9,183.2l19.9,13l27-41.5l-19.9-13L173.9,183.2z"
              />
            </svg>
          </div>
          <h3 className="text-center font-extrabold font-orange">
            SECURITY <br /> GADGETS
          </h3>
          <p className="mb-3 px-0 text-center">
            Technology and Innovation has changed the way we approach our
            personal and organizational safety. We make that choice easy for
            you, we provide security devices and gadgets to help direct your
            safety and security footsteps.
          </p>
        </div>

        <div className="sm:w-1/3 w-full p-5 bg-gray-50 m-4">
          <div className="rounded-full mb-3 mx-auto shadow-lg w-16 h-16 p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlnsSvgjs="http://svgjs.com/svgjs"
              version="1.1"
              width="34"
              height="34"
              x="0"
              y="0"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
              xmlSpace="preserve"
              className="mx-auto max-w-full"
            >
              <g>
                <g xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="m407 357c-31.706 0-57.5-25.794-57.5-57.5s25.794-57.5 57.5-57.5 57.5 25.794 57.5 57.5-25.794 57.5-57.5 57.5z"
                    fill="#E17055"
                    data-original="#000000"
                  />
                  <path
                    d="m105 357c-31.706 0-57.5-25.794-57.5-57.5s25.794-57.5 57.5-57.5 57.5 25.794 57.5 57.5-25.794 57.5-57.5 57.5z"
                    fill="#E17055"
                    data-original="#000000"
                  />
                  <path
                    d="m356 40c-18.955 0-35.996-10.532-44.472-27.484l-2.111-4.223c-2.541-5.083-7.735-8.293-13.417-8.293h-80c-5.682 0-10.876 3.21-13.417 8.292l-2.111 4.222c-8.476 16.954-25.517 27.486-44.472 27.486-8.284 0-15 6.716-15 15v85.597c0 33.654 10.619 65.715 30.708 92.716 20.089 27 47.747 46.384 79.982 56.054 1.406.422 2.858.633 4.31.633s2.904-.211 4.31-.633c32.235-9.67 59.893-29.054 79.982-56.054 20.089-27.001 30.708-59.062 30.708-92.716v-85.597c0-8.284-6.716-15-15-15zm-46.967 87.322-56.568 56.568c-2.929 2.929-6.768 4.394-10.606 4.394s-7.678-1.464-10.606-4.394l-28.284-28.284c-5.858-5.858-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l17.678 17.677 45.962-45.961c5.857-5.858 15.355-5.858 21.213 0 5.856 5.858 5.856 15.355-.002 21.213z"
                    fill="#E17055"
                    data-original="#000000"
                  />
                  <g>
                    <g>
                      <path
                        d="m468.34 361.834c-15.803 15.553-37.468 25.166-61.34 25.166-23.91 0-45.607-9.643-61.416-25.241-8.392 6.046-15.861 13.297-22.136 21.524l8.242 7.219c24.982 21.881 39.31 53.408 39.31 86.498v35h126c8.284 0 15-6.716 15-15v-50c0-35.016-17.233-66.079-43.66-85.166z"
                        fill="#e17155c7"
                        data-original="#000000"
                      />
                      <path
                        d="m43.66 361.834c-26.427 19.087-43.66 50.15-43.66 85.166v50c0 8.284 6.716 15 15 15h126v-35c0-33.09 14.328-64.617 39.31-86.498l8.242-7.219c-6.275-8.227-13.744-15.478-22.136-21.524-15.809 15.598-37.506 25.241-61.416 25.241-23.872 0-45.537-9.613-61.34-25.166z"
                        fill="#e17155c7"
                        data-original="#000000"
                      />
                    </g>
                    <path
                      d="m311.924 413.07c-14.116 14.73-33.96 23.93-55.924 23.93s-41.808-9.2-55.924-23.93c-17.802 15.592-29.076 38.462-29.076 63.93v35h170v-35c0-25.468-11.274-48.338-29.076-63.93z"
                      fill="#e17155c7"
                      data-original="#000000"
                    />
                  </g>
                  <path
                    d="m256 407c-26.192 0-47.5-21.308-47.5-47.5s21.308-47.5 47.5-47.5 47.5 21.308 47.5 47.5-21.308 47.5-47.5 47.5z"
                    fill="#E17055"
                    data-original="#000000"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h3 className="text-center font-extrabold font-orange">
            SECURITY <br /> TRAINING
          </h3>
          <p className="mb-3 px-0 text-center">
            We believe security is the bedrock of any organization. We offer
            custom made security training and orientations for staff and
            personnel of various organizations looking to bolster their security
            strategies.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
