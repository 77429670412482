import { useDisclosure } from "@chakra-ui/react";
import TeamModal from "./TeamModal";
import React, { useState } from "react";
import { BounceIn, SlideLeft } from "./common/scrollrevel/ScrollAnimation";

const Team = ({ teamCollection }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userModal, setUserModal] = useState();

  const setCurrentUserModal = (e) => {
    const user = e.target.getAttribute("data-user");

    const findUser = teamCollection.items.find(
      (userName) => userName.name === user
    );

    setUserModal(findUser);
  };

  return (
    <div className="w-5/6 mx-auto mt-36 flex-wrap text-gray-500">
      <p className="text-3xl mb-8">Meet the team</p>
      <div className="flex flex-col">
        {teamCollection.items.map(
          ({
            description,
            name,
            profileImage,
            roleInTeam,
            titles,
            twitter,
            linkedin,
          }) => {
            return (
              <React.Fragment key={name}>
                <div className="vision flex flex-wrap sm:flex-nowrap mb-10 flex-col sm:flex-row">
                  <SlideLeft>
                    <div className="img-team">
                      <img
                        src={profileImage.url}
                        alt={`Portrait of ${name}`}
                        className="w-full"
                      />
                    </div>
                  </SlideLeft>
                  <BounceIn>
                    <div className="sm:ml-6 sm:mt-0 mt-9 ml-0 team-desc justify-center flex flex-col">
                      <p className="text-2xl flex items-center">
                        <span>{name}</span>{" "}
                        <span className="text-sm self-end"> {titles}</span>{" "}
                        {twitter !== null ? (
                          <a href={twitter} target="_blanck">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#666"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-twitter mx-4"
                            >
                              <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                            </svg>
                          </a>
                        ) : null}{" "}
                        {linkedin !== null ? (
                          <a href={linkedin} target="_blanck">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#666"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-linkedin"
                            >
                              <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                              <rect x="2" y="9" width="4" height="12"></rect>
                              <circle cx="4" cy="4" r="2"></circle>
                            </svg>
                          </a>
                        ) : null}
                      </p>
                      <p className="text-gray-500 overflow-ellipsis main-desc">
                        {description}
                      </p>

                      <a
                        href="#ee"
                        className=""
                        onClick={(e) => {
                          onOpen();
                          setCurrentUserModal(e);
                        }}
                        data-user={name}
                      >
                        read more
                      </a>
                    </div>
                  </BounceIn>
                </div>
              </React.Fragment>
            );
          }
        )}

        <TeamModal
          onOpen={isOpen}
          onClose={onClose}
          isOpen={isOpen}
          description={userModal}
        />
      </div>
    </div>
  );
};

export default Team;
