import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

function TeamModal({ isOpen, onClose, description }) {
  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      size="xl"
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex">
            <div style={{ height: "50px" }}>
              <img
                src={description?.profileImage?.url}
                width="50"
                height="50"
                className="rounded-md max-w-full"
                alt={`portrait of ${description?.name}`}
              />
            </div>

            <div className="flex flex-col ml-3">
              <h3>{description?.name}</h3>
              <h4 className="text-xs text-yellow-600">
                {description?.roleInTeam}
              </h4>
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {description?.description}
          <h4 className="my-3 font-semibold">Education:</h4>
          <ul>
            {description?.education.map((education) => {
              return <li className="list-items">{education}</li>;
            })}
          </ul>
          <h4 className="my-3 font-semibold">
            I am a proud member of these renowned and reputable Professional
            Security Organizations:
          </h4>
          <ul>
            {description?.membershipNew.map((membership) => {
              return <li className="list-items"> {membership}</li>;
            })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TeamModal;
