import "./App.css";
import Hero from "./components/Hero";
import Main from "./components/Main";
import "./App.css"; // If your code is for ES6 or higher
import useContentful from "./useContentful";
import PhotoGallery from "./components/PhotoGallery";
import Articles from "./components/Articles";
import Partner from "./components/Partner";
import Footer from "./components/Footer";

const query = `
query {
  teamCollection(limit: 5) {
    items {
      name
      description
      titles
      roleInTeam
      linkedin
      twitter
      curriculumVitae {
        url
      }
      profileImage {
        url
      }

      education 
      membershipNew
    }
  }
}`;

function App() {
  let { data, errors } = useContentful(query);
  // const [hero, setHero] = useState({ header: "", description: "" });

  if (!data)
    return (
      <div className="loader__container">
        <div className="spinner-box" style={{ position: "relative" }}>
          <div className="configure-border-1">
            <div className="configure-core"></div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="40"
            viewBox="0 0 244.22 66.148"
            style={{ position: "absolute", top: "20%", left: "25%" }}
          >
            <g
              id="Group_20"
              data-name="Group 20"
              transform="translate(-41 -99.508)"
            >
              <g
                id="Layer_1"
                data-name="Layer 1"
                transform="translate(41.203 93)"
              >
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M34.776-64.492v23.92H32.568v-.644q0-1.38-.046-3.68a40.247,40.247,0,0,0-.414-4.876,21.113,21.113,0,0,0-1.978-6.762,4.923,4.923,0,0,0-4.646-3.082,7.213,7.213,0,0,0-1.748.23,10.043,10.043,0,0,0-2.024.782,8.993,8.993,0,0,0-3.5,3.036,7.276,7.276,0,0,0-1.2,4.048,8.721,8.721,0,0,0,.23,1.932,10.526,10.526,0,0,0,.69,2.024,16.1,16.1,0,0,0,3.45,4.554,22.258,22.258,0,0,0,6.118,4,26.864,26.864,0,0,0,3.68,2.254q2.208,1.15,4.6,2.622a32.207,32.207,0,0,1,7.13,6.072,14.012,14.012,0,0,1,3.266,9.66,18.911,18.911,0,0,1-.138,2.254q-.138,1.15-.414,2.438-1.932,8.1-8,11.73A25.163,25.163,0,0,1,24.472,1.656H23.184A29.118,29.118,0,0,1,7.314-3.818,16.24,16.24,0,0,1,0-17.572a18.916,18.916,0,0,1,.552-4.416A25.31,25.31,0,0,1,2.208-26.68a25.193,25.193,0,0,1,4-4.83A15.4,15.4,0,0,1,10.4-34.316a16.476,16.476,0,0,1,2.852-.966,12.11,12.11,0,0,1,2.76-.322A12.932,12.932,0,0,1,24.2-32.8a11.482,11.482,0,0,1,4.416,6.118,3.363,3.363,0,0,1,.23.874,7.921,7.921,0,0,1,.046.874,5.862,5.862,0,0,1-2.024,4.324q-2.024,1.932-6.624,2.576V-20.24a6.751,6.751,0,0,0,3.174-2.392,6.039,6.039,0,0,0,1.058-2.944,6.9,6.9,0,0,0-2.622-5.2,8.888,8.888,0,0,0-6.118-2.346q-.552,0-1.058.046a4.7,4.7,0,0,0-1.058.23,8.648,8.648,0,0,0-2.162.874,9.713,9.713,0,0,0-2.07,1.61A14.762,14.762,0,0,0,6.21-25.806a17.049,17.049,0,0,0-1.334,4.922,4.885,4.885,0,0,1-.046.69,4.246,4.246,0,0,0-.046.6v.276q.092,6.808,5.474,12.512a17.447,17.447,0,0,0,13.2,5.7A14.985,14.985,0,0,0,25.116-1.2l1.656-.184a16.327,16.327,0,0,0,9.982-5.2,17.441,17.441,0,0,0,4.278-8.694,8.1,8.1,0,0,0,.23-1.426q.046-.69.046-1.334a13.662,13.662,0,0,0-1.288-5.934,9.444,9.444,0,0,0-3.772-4.186q-4.784-3.036-8.74-5.336A71.132,71.132,0,0,1,20.608-38,21.844,21.844,0,0,1,15.5-43.148a11.271,11.271,0,0,1-1.794-6.44,18.549,18.549,0,0,1,.184-2.53,26.566,26.566,0,0,1,.552-2.806,12.129,12.129,0,0,1,4.646-5.06A13.7,13.7,0,0,1,26.5-62.376h1.1a3.025,3.025,0,0,1,.736.092,7.648,7.648,0,0,1,2.162.506,12,12,0,0,1,2.07,1.058v-3.772Z"
                  transform="translate(-0.203 71)"
                  fill="#7e2727"
                />
                <circle
                  id="Ellipse_1"
                  data-name="Ellipse 1"
                  cx="5.91"
                  cy="5.91"
                  r="5.91"
                  transform="matrix(0.045, -0.999, 0.999, 0.045, 14.965, 65.413)"
                  fill="#374151"
                />
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M63.009,215.8l-.065.1a6.831,6.831,0,1,1-6.624.076c.068-.041.139-.079.207-.114l-.057-.1c-.071.035-.139.074-.207.114a6.951,6.951,0,1,0,6.746-.074Z"
                  transform="translate(-38.565 -162.01)"
                  fill="#374151"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M70.557,198h.079a2.883,2.883,0,0,1,2.872,2.883v4.28h-.351v-4.286a2.507,2.507,0,0,0-2.493-2.5h0a2.507,2.507,0,0,0-2.493,2.5v1.531h-.351v-1.539A2.812,2.812,0,0,1,70.557,198Z"
                  transform="translate(-49.527 -149.089)"
                  fill="#374151"
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M74.348,222.26a1.428,1.428,0,0,1,.94,2.5v3.815a.935.935,0,0,1-.929.929H74.34a.932.932,0,0,1-.929-.929v-3.815a1.428,1.428,0,0,1,.937-2.5Z"
                  transform="translate(-53.242 -166.739)"
                  fill="#fefefe"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M65.9,215.864c-.068.035-.139.074-.207.114l-.057-.1c.068-.041.136-.079.207-.114Z"
                  transform="translate(-47.943 -162.01)"
                  fill="#201e1e"
                />
              </g>
            </g>
          </svg>
          <div className="configure-border-2">
            <div className="configure-core"></div>
          </div>
        </div>
      </div>
    );
  if (errors)
    return (
      <div className="loader__container">
        <div>
          😔
          <a href="/">Try Again</a>
        </div>
        ;
      </div>
    );
  return (
    <div className="App">
      <Hero />
      <Main teamCollection={data.teamCollection} />
      <Articles />
      <PhotoGallery />
      <Partner />
      <Footer />
    </div>
  );
}

export default App;
