import { useState, useEffect } from "react";

const { REACT_APP_CDA_TOKEN } = process.env;

function useContentful(query) {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/way4scfoh3fd`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${REACT_APP_CDA_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((result) => result.json())
      .then(({ data, errors }) => {
        if (errors) setErrors(errors);
        if (data) setData(data);
      })
      .catch((errors) => setErrors([errors]));
  }, [query]);

  return { data, errors };
}

export default useContentful;
