import { Bounce, Fade, Reveal, Slide } from "react-reveal";

export const BounceIn = ({ children }) => {
  return <Bounce>{children}</Bounce>;
};

export const FadeIn = ({ children }) => {
  return <Fade bottom>{children}</Fade>;
};

export const RevealScroll = ({ children }) => {
  return <Reveal>{children}</Reveal>;
};

export const SlideLeft = ({ children }) => {
  return <Slide left>{children}</Slide>;
};

export const SlideRight = ({ children }) => {
  return <Slide right>{children}</Slide>;
};
