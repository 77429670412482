import React from "react";
import useContentful from "../useContentful";
import { RevealScroll } from "./common/scrollrevel/ScrollAnimation";

const query = `
query {
photoGalleryCollection(limit: 15){
  items{
    title
    imagesCollection{
      items{
        imageCaption
      photo{
        url
      }
      }
    }
  }
}
}`;

const PhotoGallery = () => {
  let { data, errors } = useContentful(query);

  if (!data) {
    return <div>Loading gallery</div>;
  } else if (errors) {
    return <div>An error occured loading images </div>;
  } else
    return (
      <div className="container w-5/6">
        <h2 className="text-left mt-32 mb-3">RECENT ACTIVITIES</h2>
        <p className="text-left -mb-4 text-3xl">
          The world is evolving <br />
          and we're keeping up.
        </p>
        {data.photoGalleryCollection.items.map((gallery, idx) => {
          return (
            <React.Fragment key={idx}>
              <h3 className="text-2xl max-w-2xl mt-24 mb-5">{gallery.title}</h3>
              <div className="gallery">
                {gallery.imagesCollection.items.map((gallery, idx) => {
                  return (
                    <RevealScroll key={idx}>
                      <figure class="gallery-frame">
                        <img
                          className="gallery-img"
                          src={gallery.photo.url}
                          alt={gallery.imageCaption}
                          title={gallery.imageCaption}
                        />
                        <figcaption>{gallery.imageCaption}</figcaption>
                      </figure>
                    </RevealScroll>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
};

export default PhotoGallery;
