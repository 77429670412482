import dotted from "./svgs/dotted_svg.svg";
import { SlideLeft, RevealScroll } from "./common/scrollrevel/ScrollAnimation";

const About = () => {
  return (
    <section className="text-gray-500 my-20 relative" id="about">
      <div className="w-5/6 mx-auto ">
        <div className="w-full sm:w-1/2">
          <RevealScroll>
            <h2 className="text-left mb-3">ABOUT US</h2>
            <p className="text-left mb-10 text-2xl">
              We are a team of Security Professionals with a sense of
              responsibility for the protection of lives and properties.
            </p>
          </RevealScroll>
        </div>
      </div>

      <div className="w-5/6 mx-auto flex flex-wrap flex-col md:flex-row">
        <div className="flex relative flex-1">
          <div className="bg-locks w-full  z-20"></div>
          <img
            src={dotted}
            alt="dotted marks"
            className="absolute z-10 -bottom-10 -right-10 mr-4"
          />
        </div>

        <div className="flex-1 z-20">
          {" "}
          <RevealScroll>
            <p className="text-left mt-20 md:ml-10 ">
              We play an essential role in rendering peace of mind to
              organizations all over the world through our strategic security
              solutions and consulting services. The mission is to create the
              framework for visionary security policies in the area of defence,
              national security, domestic terrorism, counter-terrorism, and
              conflict management amongst others through our services. As a
              provider of a fundamental service, we recognise our social
              responsibility. <br />
              Our goal is to play an active role in the security relationship
              between the public sector, the private sector and the society.
            </p>

            {/* <a href="#re">read more</a> */}
          </RevealScroll>
        </div>
      </div>
      <SlideLeft>
        <div className="bg-gray-100 absolute min-height-card md:-bottom-40 bottom-28 left-0 z-0"></div>
      </SlideLeft>
    </section>
  );
};

export default About;
