import deco from './images/deco_logo.png';
import standtall from './images/stand-tall.jpg';

const partners = [
  {
    name: 'DECO International Security Corporation',
    logo: deco,
    address: 'Miami Beach, Florida, United States of America',
    url: 'https://www.decointernationalsecurity.com/',
    description:
      '  Specialists in Commercial, Industrial, Government, Residential, as well as luxury Hotel & Retail Security/Loss Prevention Services since 2001. With a MISSION to enhance successes by providing the highest standard in security services.',
  },

  {
    name: 'StandTall Africa Initiative',
    logo: standtall,
    address: 'Plot 1019, Gimbiya Street, Area 11, Garki District, Abuja.',
    url: '',
    description:
      'StandTall Africa Initiative, a Non-Governmental Organization that works to improve the security and lives of the people in the world.',
  },
];

const Partner = () => {
  return (
    <section className='bg-gray-50 py-8 mt-24'>
      <div className=' mx-auto my-12 flex items-center text-center flex-col max-w-4xl'>
        <h5 className='text-2xl mb-8'>Our Trusted Partners</h5>
        <div className='flex gap-12 flex-wrap'>
          {partners.map(({ name, logo, address, url, description }) => (
            <div className='flex flex-col items-center justify-items-center flex-1 gap-2'>
              <h6 className='text-2xl text-gray-400'>{name}</h6>
              <p className='mb-10'>{address}</p>

              <div className='max-w-xs'>
                <img src={logo} alt={`Our partner ${name} logo`} width={100} />
              </div>

              <p>{description}</p>

              {url && (
                <a
                  href={url}
                  className='text-blue-600 font-bold mt-6'
                  target='_blanck'
                >
                  Visit Website
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partner;
