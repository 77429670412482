import dotted from "./svgs/dotted_svg.svg";

import {
  BounceIn,
  SlideLeft,
  SlideRight,
} from "./common/scrollrevel/ScrollAnimation";

const Vision = () => {
  return (
    <div
      className="grid grid-cols-1 lg:grid-cols-2 md:mt-96 text-gray-500"
      id="vision"
    >
      <SlideLeft>
        <div className="w-5/6 ml-10 lg:ml-auto pr-10" id="mission">
          <h2 className="text-left mb-3">WHY CHOOSE US</h2>
          <p className="text-left mb-10 text-3xl">
            What makes us <br />
            different?
          </p>
          <div className="flex items-center mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50.364"
              height="50.364"
              viewBox="0 0 50.364 50.364"
              className="mr-3"
            >
              <path
                id="target"
                d="M61.591,18.761a.835.835,0,0,0-.621-.582l-5.12-1.157L54.693,11.9a.835.835,0,0,0-1.406-.406L47.4,17.385a23.827,23.827,0,1,0,8.09,8.09l5.891-5.891a.836.836,0,0,0,.211-.823ZM57.244,37.788A22.155,22.155,0,1,1,46.179,18.606l-2.7,2.7a.828.828,0,0,0-.064.084,18.411,18.411,0,1,0,8.066,8.077.824.824,0,0,0,.087-.071l2.7-2.7a22.081,22.081,0,0,1,2.976,11.093Zm-7.751-8.457a16.692,16.692,0,1,1-5.952-5.958l.88,3.9-.844.844A12.9,12.9,0,1,0,44.758,29.3l.844-.844ZM38.678,37.788a3.606,3.606,0,1,1-1.716-3.058L34.494,37.2a.835.835,0,1,0,1.182,1.181l2.467-2.467A3.565,3.565,0,0,1,38.678,37.788Zm-.514-4.261a5.285,5.285,0,1,0,1.181,1.182l4.229-4.229A11.225,11.225,0,1,1,42.393,29.3Zm15.681-8.773L50.709,27.89l-.47-.106-.018,0-4.185-.942-1.055-4.671L53.4,13.748l.939,4.156a.836.836,0,0,0,.631.631l4.156.939Z"
                transform="translate(-11.261 -11.25)"
                fill="#e68b75"
              />
            </svg>

            <p className="text-3xl vision">Our Mission</p>
          </div>
          <p className="text-left ">
            To Provide individuals, Government Institutions and private
            businesses stable and proven security strategies for the safety and
            security of lives and properties.
          </p>

          <div className="flex items-center mt-8 mb-4">
            <svg
              id="Group_17"
              data-name="Group 17"
              xmlns="http://www.w3.org/2000/svg"
              width="55.355"
              height="50.274"
              viewBox="0 0 55.355 50.274"
              className="mr-3"
            >
              <g id="electric-light-bulb" transform="translate(0 0)">
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M153.624,74.564a.973.973,0,0,0-.973-.973A10.2,10.2,0,0,0,142.461,83.78a.973.973,0,1,0,1.947,0,8.253,8.253,0,0,1,8.243-8.243A.972.972,0,0,0,153.624,74.564Z"
                  transform="translate(-125.405 -67.321)"
                  fill="#e7917d"
                />
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M108.305,49.033a14.774,14.774,0,0,0,3.122,9.133l.031.038c4.26,5.913,3.2,8.726,3.2,8.731a.974.974,0,0,0,.469,1.294.956.956,0,0,0,.412.091.973.973,0,0,0,.882-.561c.176-.374,1.568-3.857-3.435-10.765a1,1,0,0,0-.07-.087,12.845,12.845,0,0,1-2.664-7.876,12.969,12.969,0,1,1,23.241,7.92c-5.006,6.913-3.558,10.436-3.377,10.815a.964.964,0,0,0,1.271.455.984.984,0,0,0,.5-1.274c-.012-.028-1.127-2.9,3.171-8.833a14.78,14.78,0,0,0,3.083-9.083,14.916,14.916,0,0,0-29.832,0Z"
                  transform="translate(-95.338 -32.574)"
                  fill="#e7917d"
                />
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M173.852,327.421v-.827a2.394,2.394,0,0,0-2.391-2.391H158.346a2.394,2.394,0,0,0-2.391,2.391v.827a2.394,2.394,0,0,0,2.391,2.391h13.116A2.393,2.393,0,0,0,173.852,327.421Zm-15.951,0v-.827a.445.445,0,0,1,.445-.445h13.116a.445.445,0,0,1,.445.445v.827a.445.445,0,0,1-.445.445H158.346A.445.445,0,0,1,157.9,327.421Z"
                  transform="translate(-137.283 -287.928)"
                  fill="#e7917d"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M181.931,383.412a.973.973,0,0,0-.973.973v2.57a.974.974,0,0,0,.569.886l5.245,2.393a.973.973,0,0,0,.819-.006l5.259-2.482a.972.972,0,0,0,.558-.88v-2.481a.973.973,0,0,0-1.947,0v1.865l-4.293,2.026-4.265-1.946v-1.945A.973.973,0,0,0,181.931,383.412Z"
                  transform="translate(-159.293 -340.048)"
                  fill="#e7917d"
                />
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M360.811,258.387a.973.973,0,0,0-.205-1.361l-6.6-4.868a.973.973,0,0,0-1.155,1.566l6.6,4.868a.974.974,0,0,0,1.361-.205Z"
                  transform="translate(-310.255 -224.342)"
                  fill="#e7917d"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M46.621,27.954a.974.974,0,0,0,1.156-1.567l-6.751-4.977a.973.973,0,0,0-1.155,1.567Z"
                  transform="translate(-34.749 -21.22)"
                  fill="#e7917d"
                />
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M46.938,249.367l-7.056,5.2a.974.974,0,0,0,1.156,1.567l7.056-5.2a.974.974,0,0,0-1.156-1.567Z"
                  transform="translate(-34.759 -221.885)"
                  fill="#e7917d"
                />
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M355.254,26.074a.974.974,0,0,0,1.156,1.567l6.315-4.656a.973.973,0,0,0-1.155-1.566Z"
                  transform="translate(-312.373 -21.228)"
                  fill="#e7917d"
                />
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M392.33,156.933h-7.444a.973.973,0,1,0,0,1.947h7.444a.973.973,0,1,0,0-1.947Z"
                  transform="translate(-337.948 -140.685)"
                  fill="#e7917d"
                />
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M9.628,157.906a.973.973,0,0,0-.973-.973H.973a.973.973,0,1,0,0,1.947H8.655A.974.974,0,0,0,9.628,157.906Z"
                  transform="translate(0 -140.685)"
                  fill="#e7917d"
                />
              </g>
            </svg>

            <p className="text-3xl vision">Our Vision</p>
          </div>
          <p className="text-left">
            To be recognized as leaders in strategic security policies globally.
            Security is a global phenomenon hence, our vision is to be one of
            the Global leaders in security strategies and its implementation. We
            want to be responsible for the restoration of global and national
            security through our adept observational, detailed-oriented
            strategic security thinking. We intend to begin one at a time, one
            Government Institution and one private business at a time.
          </p>
        </div>
      </SlideLeft>

      <SlideRight>
        <div className="flex relative ml-0 lg:ml-12 lg:mt-0 mt-10 flex-1">
          <div className="man__bulb z-10"></div>
          {/* <img
          src={manbulb}
          className="z-10 mt-14 lg:mt-0 max-w-full"
          alt="padlocks on a gate showing security"
        /> */}
          <BounceIn>
            <img
              src={dotted}
              alt="dotted marks"
              className="absolute z-0 -right-10 -bottom-10"
            />

            <img
              src={dotted}
              alt="dotted marks"
              className="absolute z-0 -left-10 -bottom-10"
            />
          </BounceIn>
        </div>
      </SlideRight>
    </div>
  );
};

export default Vision;
